/* eslint-disable linebreak-style */
import axios from 'axios';
import { reset } from 'redux-form';
import {
  SUBSCRIPTION_DETAIL_FETCH_REQUEST,
  SUBSCRIPTION_DETAIL_FETCH_SUCCESS,
  SUBSCRIPTION_DETAIL_FETCH_ERROR,
  SUBSCRIPTION_DETAIL_ADD_REQUEST,
  SUBSCRIPTION_DETAIL_ADD_SUCCESS,
  SUBSCRIPTION_DETAIL_ADD_ERROR,
  SUBSCRIPTION_DETAIL_START_REQUEST,
  SUBSCRIPTION_DETAIL_START_SUCCESS,
  SUBSCRIPTION_DETAIL_START_ERROR,
  SUBSCRIPTION_DETAIL_UPDATE_REQUEST,
  SUBSCRIPTION_DETAIL_UPDATE_SUCCESS,
  SUBSCRIPTION_DETAIL_UPDATE_ERROR,
  SUBSCRIPTION_DETAIL_CLEAR
} from './actionTypes';

import { history } from "../index";
import { updateSpinner } from "./spinnerActions";
import { clearFleetDetail } from "./customerDetailActions";
import { clearLeadDetail } from "./leadDetailActions";
import { clearSubscriptionEffectiveDate } from "./billingSubscription";
import { clearBillingTax } from "./billingTax";
import { clearSolutionDetail } from "./solutionDetailActions";

export const fetchSubscriptionDetail = (subscriptionId) => async (dispatch, state) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_DETAIL_FETCH_REQUEST , request: true});
  let url = `/api/core/subscriptions/${subscriptionId}/`;

  const { mode } = state().account;
  url += `?mode=${mode}`;

  try {
    const res = await axios.get(url);
    dispatch({ type: SUBSCRIPTION_DETAIL_FETCH_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    return res.data;
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_DETAIL_FETCH_ERROR, error });
    dispatch(updateSpinner(false));
    throw error
  }
};


export const startSubscriptionDetail = (subscriptionId, data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_DETAIL_START_REQUEST , request: true});
  const url = `/api/core/subscriptions/${subscriptionId}/`;
  try {
    const res = await axios.patch(url, data);
    dispatch({ type: SUBSCRIPTION_DETAIL_START_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_DETAIL_START_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const addSubscriptionDetail = (data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_DETAIL_ADD_REQUEST , request: true});
  const url = `/api/core/subscriptions/`;
  try {
    const res = await axios.post(url, data);
    dispatch({ type: SUBSCRIPTION_DETAIL_ADD_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    history.push(`/admin/customers/${res.data['id']}/`);
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_DETAIL_ADD_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const updateSubscriptionDetail = (subscriptionId, data) => async (dispatch) => {
  dispatch(updateSpinner(true));
  dispatch({ type: SUBSCRIPTION_DETAIL_UPDATE_REQUEST , request: true});
  const url = `/api/core/subscriptions/${subscriptionId}/`;
  try {
    const res = await axios.patch(url, data);
    dispatch({ type: SUBSCRIPTION_DETAIL_UPDATE_SUCCESS, data: res.data });
    dispatch(updateSpinner(false));
    history.push(`/admin/customers/${subscriptionId}/`);
  } catch (error) {
    dispatch({ type: SUBSCRIPTION_DETAIL_UPDATE_ERROR, error });
    dispatch(updateSpinner(false));
    return error;
  }
};

export const clearSubscriptionDetail = () => (dispatch) => {
  dispatch({type: SUBSCRIPTION_DETAIL_CLEAR});
};

export const clearSubscriptionData = () => async (dispatch) => {
  await dispatch(clearSubscriptionDetail());
  await dispatch(clearSolutionDetail());
  await dispatch(clearBillingTax());
  await dispatch(clearSubscriptionEffectiveDate());
  await dispatch(clearLeadDetail());
  await dispatch(clearFleetDetail());
  await dispatch(reset('CustomerSubscriptionEditNew'));

};
